<template>
  <div>
    <div>
      <!-- <TheNavbarHorizontal></TheNavbarHorizontal> -->
    </div>

    <vs-row vs-lg="12" vs-md="12" vs-xs="12">
      <img src="../../assets/images/about-us.png" alt="" style="width: 100%" />
    </vs-row>
    <!--  -->

    <vs-row
      style="padding-left: 15%; padding-top: 2%"
      vs-type="flex"
      vs-justify="normal"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <h2 style="color: #00c425">About</h2>
    </vs-row>

    <vs-row
      style="padding-left: 15%"
      vs-type="flex"
      vs-justify="normal"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <h1 style="color: #132953">doclinia</h1>
      <vs-row class="con-sliderr"
        ><vs-slider color="success"> </vs-slider>
      </vs-row>
    </vs-row>

    <vs-row
      style="padding-left: 15%"
      vs-type="flex"
      vs-justify="normal"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6 "
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
        ><h3>
          Doclinia, a brand of the “Cure Journey Portal” based in the UAE, is a
          comprehensive digital platform that revolutionizes traditional medical
          tourism, making it accessible globally
        </h3></vs-col
      >
    </vs-row>
    <!--  -->

    <vs-row
      style="padding-left: 15%; padding-top: 2%"
      vs-type="flex"
      vs-justify="normal"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6 "
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
          ><h3>
            With Doclinia, patients can conveniently book online consultations,
            engage in video calls with international doctors, seek second
            opinions, and receive follow-up care. The platform also facilitates
            scheduling surgical interventions and offers cosmetic packages
          </h3>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <video style="width: 100%" controls>
            <source src="../../assets/DocliniaDemoVideo.mp4" type="video/mp4" />
          </video>
        </vs-col>
      </vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6 "
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
        ><h3>
          Doclinia goes beyond medical services by providing support to
          customers in arranging their travel packages
        </h3>
      </vs-col>
      <vs-row vs-lg="6" style="margin-top: 2%" vs-md="12" vs-sm="12" vs-xs="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6 "
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
          ><h3>
            It establishes a network of esteemed doctors and hospitals, enabling
            patients to connect with renowned physicians abroad and organize
            interventions outside their home country
          </h3>
        </vs-col>
      </vs-row>
      <vs-row vs-lg="6" style="margin-top: 2%" vs-md="12" vs-sm="12" vs-xs="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6 "
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
          ><h3>
            Visit our website to explore the possibilities of Doclinia and its
            wide range of services for a seamless medical tourism experience
          </h3>
        </vs-col>
      </vs-row>
    </vs-row>
    <br />
    <br />
    <!--  -->
    <div>
      <vs-row vs-justify="center">
        <vs-row
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="4"
          vs-xs="12"
        >
          <div class="back-grewond2">
            <vs-row
              style="padding-left: 15%; padding-top: 1%"
              vs-type="flex"
              vs-justify="normal"
              vs-align="center"
              vs-lg="12"
              vs-md="12"
              vs-sm="12"
              vs-xs="12"
            >
              <h1 style="color: #fff; font-size: 291%">Vision</h1>
              <vs-row class="con-sliderr"
                ><vs-slider color="success"> </vs-slider>
              </vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="10"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
                ><h3 style="color: #fff; font-size: 200%">
                  It establishes a network of esteemed doctors and hospitals,
                  enabling patients to connect with renowned physicians abroad
                  and organize interventions outside their home country
                </h3>
              </vs-col>
            </vs-row>
            <br />
            <vs-row
              style="padding-left: 15%; padding-top: 1%"
              vs-type="flex"
              vs-justify="normal"
              vs-align="center"
              vs-lg="12"
              vs-md="12"
              vs-sm="12"
              vs-xs="12"
            >
              <h1 style="color: #fff; font-size: 291%">Mission</h1>
              <vs-row class="con-sliderr"
                ><vs-slider color="success"> </vs-slider>
              </vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="10"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
                ><h3 style="color: #fff; font-size: 200%">
                  It establishes a network of esteemed doctors and hospitals,
                  enabling patients to connect with renowned physicians abroad
                  and organize interventions outside their home country
                </h3>
              </vs-col>
            </vs-row>
            <br />
            <br />
          </div>
        </vs-row>
      </vs-row>
      <!--  -->
    </div>
    <!--  -->

    <vs-row
      style="padding-left: 15%; padding-top: 2%"
      vs-type="flex"
      vs-justify="normal"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <h2 style="color: #00c425">Join</h2>
    </vs-row>

    <vs-row
      style="padding-left: 15%"
      vs-type="flex"
      vs-justify="normal"
      vs-align="center"
      vs-lg="12"
      vs-md="12"
      vs-sm="12"
      vs-xs="12"
    >
      <h1 style="color: #132953">Doclinia</h1>
      <vs-row class="con-sliderr"
        ><vs-slider color="success"> </vs-slider>
      </vs-row>
    </vs-row>
    <!--  -->
    <div>
      <vs-row
        style="padding-left: 15%"
        vs-type="flex"
        vs-justify="normal"
        vs-align="center"
        vs-lg="12"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-tabs :color="colorx">
          <vs-tab @click="colorx = 'success'" label="DOCTORS">
            <div class="con-tab-ejemplo">
              <vs-row
                style="padding-top: 1%"
                vs-type="flex"
                vs-justify="normal"
                vs-align="center"
                vs-lg="12"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
              >
                <h1 style="color: #132953; font-size: 200%">
                  unlock new opportunities with Doclinia:
                </h1>
                <vs-row class="con-sliderr"
                  ><vs-slider color="red"> </vs-slider>
                </vs-row>
                <vs-col
                  vs-justify="normal"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    unlock new opportunities with Doclinia:
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Connect with International Patients: Provide online
                    consultations to patients from around the world
                  </h1>
                  <!--  -->
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Effortless Payments: Receive your fees directly in your bank
                    account
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Manage Availability with Ease: Utilize a dynamic calendar to
                    efficiently manage your schedule
                  </h1>

                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Streamlined Process: Experience fully automated handling of
                    surgeries, treatments, and online appointments
                  </h1>

                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Join Us Today: Sign up for Doclinia and take your practice
                    to new heights.
                  </h1>
                  <br />
                  <br />
                  <vs-button color="success" type="filled">Success</vs-button>
                  <br />
                  <br />
                  <h5 style="color: #132953">Have Questions?</h5>
                  <br />
                  <br />
                 <h6>
                    Contact Us at
                    <a href="mailto:info@doclinia.com">  <span style="color: #00c425">info@doclinia.com</span></a> 
                    and let’s explore how we can grow together.
                  </h6>
                  <br />
                  <br />
                </vs-col>
              </vs-row>
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'danger'" label="HOSPITALS">
            <div class="con-tab-ejemplo">
              <vs-row
                style="padding-top: 1%"
                vs-type="flex"
                vs-justify="normal"
                vs-align="center"
                vs-lg="12"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
              >
                <h1 style="color: #132953; font-size: 200%">
                  Global Patient Reach, Connect with Patients Worldwide through
                  Doclinia
                </h1>
                <vs-row class="con-sliderr"
                  ><vs-slider color="red"> </vs-slider>
                </vs-row>
                <vs-col
                  vs-justify="normal"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Expand Your Horizon: Access New Patients from the GCC,
                    Middle East, and North Africa
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />

                    Unlock Growth Opportunities: Open a New Distribution Channel
                    for Your Hospital
                  </h1>
                  <!--  -->
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Spread Surgical Innovations: Promote New Techniques to Wider
                    Regions
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Tailored Support: Benefit from a Dedicated Account Manager
                    for Your Hospital
                  </h1>

                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Efficient Digital Records: Streamline Patient Data
                    Management
                  </h1>

                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Maintain Data Security: Ensure GDPR and HIPAA Compliance
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Join Doclinia Today: Sign Up to Expand Your Reach and
                    Enhance Patient Care
                  </h1>
                  <br />
                  <br />
                  <vs-button color="success" type="filled">Success</vs-button>
                  <br />
                  <br />
                  <h5 style="color: #132953">Have Questions?</h5>
                  <br />
                  <br />
                 <h6>
                    Contact Us at
                    <a href="mailto:info@doclinia.com">  <span style="color: #00c425">info@doclinia.com</span></a> 
                    and let’s explore how we can grow together.
                  </h6>
                  <br />
                  <br />
                </vs-col>
              </vs-row>
            </div>
          </vs-tab>
          <!--  -->
          <vs-tab @click="colorx = 'warning'" label="STRATEGIC ALLIANCES">
            <div class="con-tab-ejemplo">
              <vs-row
                style="padding-top: 1%"
                vs-type="flex"
                vs-justify="normal"
                vs-align="center"
                vs-lg="12"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
              >
                <h1 style="color: #132953; font-size: 200%">
                  As our collaborator, you will enjoy:
                </h1>
                <vs-row class="con-sliderr"
                  ><vs-slider color="red"> </vs-slider>
                </vs-row>
                <vs-col
                  vs-justify="normal"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Promotion of Your Services: Leverage the reach of Doclinia
                    to promote your services effectively.
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />

                    Dedicated Marketing Campaigns: Harness the power of AI tools
                    and dedicated marketing campaigns to reach international
                    patients.
                  </h1>
                  <!--  -->
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Seamless Transition to Digital: Move away from traditional
                    offline booking processes and embrace our fully digitalized
                    platform.
                  </h1>
                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Boost Patients' Awareness: Increase awareness of medical
                    tourism in your destination among patients worldwide.
                  </h1>

                  <h1 style="color: #132953; font-size: 150%">
                    <img
                      src="../../assets/images/true.png"
                      width="20px"
                      alt=""
                    />
                    Efficient Digital Records: Streamline Patient Data
                    Management
                  </h1>

                  <br />
                  <br />
                  <h4 style="color: #132953">
                    We are spearheading a digital health services revolution,
                    aiming to make them accessible to patients worldwide.
                  </h4>
                  <br />
                  <br />
                 <h6>
                    Contact Us at
                    <a href="mailto:info@doclinia.com">  <span style="color: #00c425">info@doclinia.com</span></a> 
                    and let’s explore how we can grow together.
                  </h6>
                  <br />
                  <br />
                </vs-col>
              </vs-row>
            </div>
          </vs-tab>
        </vs-tabs>
        <!--  -->
      </vs-row>
    </div>
    <!--  -->
    <div></div>
    <Footer></Footer>
  </div>
</template>
<script>
// import TheNavbarHorizontal from "../../layouts/components/navbar/TheNavbarHorizontal.vue";
import Footer from "../../components/footer/Footer.vue";

export default {
  data: {},
  components: {
    // TheNavbarHorizontal,
    Footer,
    // HorizontalNavMenuHeader,
    // TheNavbarVerticalFullPage,
    // VerticalNavMenuGrou
  },
};
</script>
<style>
.back-grewond2 {
  background-image: url("../../assets/images/4.png");
  width: 100%;
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
.con-vs-slider {
  width: 8%;
  /* position: relative; */
  left: 0;
  margin: 1% 0;
  display: block;
  min-width: 4%;
  z-index: 50;
  background: #00c425;
}
.vs-slider--circle {
  width: 0px;
  height: 0px;
  position: fixed;
  top: -6px;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  margin: 0;
  z-index: 200;
  background: #00c425;
}
.vs-slider {
  width: 50%;
  height: 4px;
  border-radius: 4px;
  background: #00c425;
  position: relative;
  cursor: pointer;
  border: 0;
  margin: 0;
  display: block;
  overflow: hidden;
}
.con-sliderr {
  /* justify-content: center; */
  /* width: 8%; */
  /* position: relative; */
  /* left: 0; */
  margin: 16px 0;
  display: block;
  min-width: 7%;
  z-index: 100;
  /* right: 50px */
}
.shadow-lg {
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 62%), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
}
</style>
